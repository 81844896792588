import { resizeCanvas } from "./waiver_v8";

    (function() {

    var messaging = {
      "first_name": {
        "en": {
          "onlyLetters": "^First name can only contain letters",
          "presence": "^First name can't be blank",
        },
        "es": {
          "onlyLetters": "^El nombre solo puede contener letras",
          "presence": "^El nombre no puede estar en blanco",
        },
        "fr": {
          "onlyLetters": "^Le prénom ne peut contenir que des lettres",
          "presence": "^Le prénom ne peut pas être vide",
        },
      },
      "last_name": {
        "en": {
          "onlyLetters": "^Last name can only contain letters",
          "presence": "^Last name can't be blank",
        },
        "es": {
          "onlyLetters": "^El apellido solo puede contener letras",
          "presence": "^El apellido no puede estar en blanco",
        },
        "fr": {
          "onlyLetters": "^Le nom de famille ne peut contenir que des lettres",
          "presence": "^Le nom ne peut pas être vide",
        },
      },
      "phone": {
        "en": {
          "presence": "^Phone can't be blank",
          "tenNumbers": "^Phone number must contain exactly 10 numbers",
          "universal": "^Phone number must contain between 9 and 15 digits",
        },
        "es": {
          "presence": "^El teléfono no puede estar en blanco.",
          "tenNumbers": "^El número de teléfono debe contener exactamente 10 números",
          "universal": "^El número de teléfono debe contener entre 9 y 15 dígitos",
        },
        "fr": {
          "presence": "^Le téléphone ne peut pas être vide",
          "tenNumbers": "^Le numéro de téléphone doit contenir exactement 10 chiffres",
          "universal": "^Le numéro de téléphone doit contenir entre 9 et 15 chiffres",
        },
      },
      "dob": {
        "en": {
          "presence": "^Date of birth can't be blank",
          "validDOB": "^Please enter a valid birth date"
        },
        "es": {
          "presence": "^La fecha de nacimiento no puede estar en blanco.",
          "validDOB": "^Por favor introduzca una fecha de cumpleaños válida"
        },
        "fr": {
          "presence": "^La date de naissance ne peut pas être vierge",
          "validDOB": "^Veuillez entrer une date de naissance valide"
        },
      },
      "email": {
        "en": {
          "presence": "^Email can't be blank",
          "validEmail": "^Please enter a valid email",
        },
        "es": {
          "presence": "^El correo electrónico no puede estar en blanco",
          "validEmail": "^Por favor introduzca una dirección de correo electrónico válida",
        },
        "fr": {
          "presence": "^L'email ne peut pas être vide",
          "validEmail": "^Veuillez saisir un e-mail valide",
        },
      },
      "custom_text": {
        "en": {
          "presence": "^This field can't be blank",
        },
        "es": {
          "presence": "^Este campo no puede estar en blanco",
        },
        "fr": {
          "presence": "^Ce champ ne peut pas être vide",
        },
      },
      "custom_drop": {
        "en": {
          "presence": "^Please select an option",
        },
        "es": {
          "presence": "^Por favor seleccione una opción",
        },
        "fr": {
          "presence": "^Veuillez sélectionner une option",
        },
      },
      "custom_check": {
        "en": {
          "checked": "^Please check the checkbox",
        },
        "es": {
          "checked": "^Por favor marque la casilla",
        },
        "fr": {
          "checked": "^Veuillez cocher la case",
        },
      },
      "team_name": {
        "en": {
          "presence": "^Team name can't be blank",
        },
        "es": {
          "presence": "^El nombre del equipo no puede estar en blanco",
        },
        "fr": {
          "presence": "^Le nom de l'équipe ne peut pas être vide",
        },
      },
      "is_participating": {
        "en": {
          "presence": "^A response to this question is required",
        },
        "es": {
          "presence": "^Se requiere una respuesta a esta pregunta",
        },
        "fr": {
          "presence": "^Une réponse à cette question est requise",
        },
      },
    }

      // These are the constraints used to validate the form
    var constraints = {
        first_name: {
          format: {
            pattern: "[A-Za-z àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇáéíóúüñ'ČčĆćDždžĐđLjljNjnjŠšŽž-]+",
            message: messaging.first_name[window.language].onlyLetters
          },
        },
        last_name: {
          format: {
            pattern: "[A-Za-z àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇáéíóúüñ'ČčĆćDždžĐđLjljNjnjŠšŽž-]+",
            message: messaging.last_name[window.language].onlyLetters
          },
        },
        email: {
          // and must be an email (duh)
          // email: true
          email: {message: messaging.email[window.language].validEmail}
        },
        phone: function(value, attributes, attributeName, options, constraints) {
          if (attributeName in attributes) {
            let constraints = {}
            switch (window.phoneFormat) {
                case "0":
                    constraints.format = {
                      pattern: /\+?1?\d{9,15}$/,
                      message: messaging.phone[window.language].universal
                    }
                    break
                case "1":
                  constraints.format = {
                    pattern: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
                    message: messaging.phone[window.language].tenNumbers
                  }
                  break
            }
            return constraints;
          }
        },
        dob: {
          presence: false,
          datetime: {
            dateOnly: true,
            earliest: moment.utc().subtract(120, 'years'),
            latest: moment.utc().subtract(0, 'years'),
            message: messaging.dob[window.language].validDOB
          },
        },
        team_name: {
          presence: false,
        },
        is_participating: {
          conditionalSelect: {
            condition: function() {
              var isParticipatingSelect = document.getElementById('is-participating-select')
              if (isParticipatingSelect) {
                var isParticipatingSelectDisplay = isParticipatingSelect.style.display
                if (isParticipatingSelectDisplay === 'block') return true
              }
              return false
            }
          },
        },
    };

        var inputs = document.querySelectorAll('input:not([type="hidden"]):not(#id_opt_in_checkbox), textarea, select');

        var dict = {}
        for(var i = 0; i < inputs.length; i++) {
            if(inputs[i].type.toLowerCase() != 'hidden') {
                if (inputs[i].id == 'id_first_name') {
                    if (inputs[i].hasAttribute('required')) {
                      constraints[inputs[i].name]['presence'] = {'message': messaging.first_name[window.language].presence};
                    }
                } else if (inputs[i].id == 'id_last_name') {
                    if (inputs[i].hasAttribute('required')) {
                      constraints[inputs[i].name]['presence'] = {'message': messaging.last_name[window.language].presence};
                    }
                } else if (inputs[i].id == 'id_email') {
                    if (inputs[i].hasAttribute('required')) {
                      constraints[inputs[i].name]['presence'] = {'message': messaging.email[window.language].presence};
                    }
                } else if (inputs[i].id == 'id_phone') {
                    if (inputs[i].hasAttribute('required')) {
                      constraints[inputs[i].name]['presence'] = {'message': messaging.phone[window.language].presence};
                    }
                } else if (inputs[i].id == 'id_dob') {
                    if (inputs[i].hasAttribute('required')) {
                        constraints[inputs[i].name]['presence'] = {'message': messaging.dob[window.language].presence};
                    }
                } else if (inputs[i].id == 'id_team_name') {
                    if (inputs[i].hasAttribute('required')) {
                        constraints[inputs[i].name]['presence'] = {'message': messaging.team_name[window.language].presence};
                    }
                } else if (inputs[i].name.substring(0, 11) == 'custom_text') {
                    if (inputs[i].hasAttribute('required')) {
                        constraints[inputs[i].name] = {};
                        constraints[inputs[i].name]['presence'] = {'message': messaging.custom_text[window.language].presence};
                    }
                } else if (inputs[i].name.substring(0, 11) == 'custom_drop') {
                    if (inputs[i].hasAttribute('required')) {
                        constraints[inputs[i].name] = {};
                        constraints[inputs[i].name]['presence'] = {'message': messaging.custom_drop[window.language].presence};
                    }
                } else if (inputs[i].name.substring(0, 12) == 'custom_check') {
                    if (inputs[i].hasAttribute('required') == true) {
                        constraints[inputs[i].name] = {};
                        constraints[inputs[i].name]['inclusion'] = {'within': [true], 'message': messaging.custom_check[window.language].checked};
                    }
                }
            }
        }



//      // These are the constraints used to validate the form
//      var constraints = {
//        first_name: {
//          presence: document.querySelector('#id_first_name').hasAttribute('required'),
//          format: {
//            pattern: "[A-Za-z ]+",
//            message: "can only contain letters"
//          },
//        },
//        last_name: {
//          presence: document.querySelector('#id_last_name').hasAttribute('required'),
//          format: {
//            pattern: "[A-Za-z '-]+",
//            message: "can only contain letters"
//          },
//        },
//        email: {
//          presence: document.querySelector('#id_email').hasAttribute('required'),
//          // and must be an email (duh)
//          email: true
//        },
//        phone: {
//          presence: document.querySelector('#id_phone').hasAttribute('required'),
//          format: {
//            pattern: "[0-9]{10}",
//            message: "number must contain exactly 10 numbers"
//          },
//        },
//      };



        validate.extend(validate.validators.datetime, {
          // The value is guaranteed not to be null or undefined but otherwise it
          // could be anything.
          parse: function(value, options) {
            return +moment.utc(value, "MM-DD-YYYY");
          },
          // Input is a unix timestamp
          format: function(value, options) {
            var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
            return moment.utc(value, "MM-DD-YYYY").format(format);
          }
        });

        validate.validators.conditionalSelect = function(value, options, key, attributes) {
          if (options.condition()) {
            if (!value) {
              return messaging.is_participating[window.language].presence
            }
          }
        }


      // Hook up the form so we can prevent it from being posted
      var form = document.querySelector("form#waiver-form");
      var button = document.querySelector("#waiver-form-button")
      button.addEventListener("click", function() {
        handleFormSubmit(form);
      });

      // Hook up the inputs to validate on the fly
      var inputs = document.querySelectorAll('input:not([type="hidden"]):not(#id_opt_in_checkbox), textarea, select')
      for (var i = 0; i < inputs.length; ++i) {
        inputs.item(i).addEventListener("change", function(ev) {
          var errors = validate(form, constraints) || {};
          showErrorsForInput(this, errors[this.name])
        });
      }

      function handleFormSubmit(form, input) {
        // validate the form against the constraints
        var errors = validate(form, constraints);
        // then we update the form to reflect the results
        showErrors(form, errors || {});
        if (!errors) {
          showSuccess();
        }
      }

      // Updates the inputs with the validation errors
      function showErrors(form, errors) {
        // We loop through all the inputs and show the errors for that input
        Array.from(form.querySelectorAll("input[name]:not([type='hidden']):not(#id_opt_in_checkbox), select[name]")).forEach(function(input) {
//        form.querySelectorAll("input[name]:not([type='hidden']), select[name]").forEach(function(input) {
          // Since the errors can be null if no errors were found we need to handle
          // that
          showErrorsForInput(input, errors && errors[input.name]);
        });
      }

      // Shows the errors for a specific input
      function showErrorsForInput(input, errors) {
        // This is the root of the input
        var formGroup = closestParent(input.parentNode, "form-group")
          // Find where the error messages will be insert into
          , messages = formGroup.querySelector(".messages");
        // First we remove any old messages and resets the classes
        resetFormGroup(formGroup);
        // If we have errors
        if (errors) {
          // we first mark the group has having errors
          formGroup.classList.add("has-error");
          // then we append all the errors
//          _.each(errors, function(error) {
        errors.forEach(function(error) {
            addError(messages, error);
          });
        } else {
          // otherwise we simply mark it as success
          formGroup.classList.add("has-success");
        }
      }

      // Recusively finds the closest parent that has the specified class
      function closestParent(child, className) {
        if (!child || child == document) {
          return null;
        }
        if (child.classList.contains(className)) {
          return child;
        } else {
          return closestParent(child.parentNode, className);
        }
      }

      function resetFormGroup(formGroup) {
        // Remove the success and error classes
        formGroup.classList.remove("has-error");
        formGroup.classList.remove("has-success");
        // and remove any old messages
//        formGroup.querySelectorAll(".help-block.error").forEach(function(el) {
        Array.from(formGroup.querySelectorAll(".help-block.error")).forEach(function(el) {
          el.parentNode.removeChild(el);
        });
      }

      // Adds the specified error with the following markup
      // <p class="help-block error">[message]</p>
      function addError(messages, error) {
        var block = document.createElement("p");
        block.classList.add("help-block");
        block.classList.add("error");
        block.innerText = error;
        messages.appendChild(block);
      }

      function openSigModal() {
        const modal = document.getElementById('sigModal')
        const body = document.querySelector('body')
        body.classList.add('modal-open')
        resizeCanvas()
        setTimeout(function() {
          modal.classList.add('show')
        }, 200)
      }
      
      function showSuccess() {
        // We made it \:D/
        openSigModal()
      }




      if (!Array.from) {
        Array.from = (function () {
          var toStr = Object.prototype.toString;
          var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
          };
          var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
          };
          var maxSafeInteger = Math.pow(2, 53) - 1;
          var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
          };
      
          // The length property of the from method is 1.
          return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;
      
            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);
      
            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
              throw new TypeError("Array.from requires an array-like object - not null or undefined");
            }
      
            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
              // 5. else
              // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
              if (!isCallable(mapFn)) {
                throw new TypeError('Array.from: when provided, the second argument must be a function');
              }
      
              // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
              if (arguments.length > 2) {
                T = arguments[2];
              }
            }
      
            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);
      
            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);
      
            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
              kValue = items[k];
              if (mapFn) {
                A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
              } else {
                A[k] = kValue;
              }
              k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
          };
        }());
      }
















    })();
