import axios from 'axios'
import { phoneFormat } from '../base/_phone_format.js'

// This function gets cookie with a given name
function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim()
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// APP SETTINGS
    var settings = document.querySelector('.settings');
    // set background color
    var body = document.querySelector('body');
//    body.style.backgroundColor = settings.getAttribute('data-background-color');

// VARIABLES

    var pickedImageH = 165;
    var pickedImageTop = 30;
    var roomCount = document.querySelector('.data-container').getAttribute('data-room-count');
    var slotItemHeight = document.getElementsByClassName('slot-item')[0].offsetHeight;
    var waiverTop = pickedImageTop + pickedImageH + slotItemHeight;



// FUNCTIONS

    function createHeadStyleTag() {
        var element  = document.createElement("style");
        var headerTextBreakPoint = 850;
        var headerTextFontSize = 62;
        var headerTextFontSize = headerTextFontSize*Math.min(1, window.innerWidth/headerTextBreakPoint);
        var gameMarginBreakPoint = (roomCount*300)+((roomCount-1)*40)+(50*2);
        var gameMarginOuter = 50;
        var gameMarginInner = 40;
        var gameMarginOuter = gameMarginOuter*Math.min(1, window.innerWidth/gameMarginBreakPoint);
        // var gameMarginInner = gameMarginInner*Math.min(1, window.innerWidth/gameMarginBreakPoint);
        var gameMarginInner = (gameMarginInner*Math.min(1, window.innerWidth/gameMarginBreakPoint))/2;
        var slotsWidth = document.querySelector('.slots').getBoundingClientRect().width;
        var slotsCenter = (window.innerWidth/2) - (slotsWidth/2);
        element.id = "layout-variables";
        element.innerHTML = '.header-text {' +
                                // 'font-size: '+headerTextFontSize+'px;'+
                                // 'margin: '+(headerTextFontSize*.8)+'px 0;'+
                                // 'line-height: '+headerTextFontSize+'px;}'+
                                'line-height: 1;}'+
                            // '.games {'+
                            //     'margin: 0 '+gameMarginOuter+'px;}'+
                            // '.game-image:not(:last-child) {'+
                            //     'margin-right: '+gameMarginInner+'px;}'+
                            // '.games {'+
                            //     'margin: '+gameMarginOuter+'px;}'+
                            // '.game-image {'+
                            //     'margin: '+gameMarginInner+'px;}'+
                            '.slots {'+
                                'top: '+(pickedImageTop+pickedImageH)+'px;'+
                                'left: 50%;}'+
//                                'transform: translateX(-50%);}'+
//                                'left: '+slotsCenter+'px;}'+
                            '.waiver {'+
                                'top: ' + waiverTop + 'px;}'
        var header = document.getElementsByTagName("HEAD")[0];
        header.appendChild(element);
    }

    // position picked image - update translate property
    function positionPickedImage(pickedGame) {
        var pickedGamePosition = pickedGame.getBoundingClientRect();
        var pickedImageW = pickedGamePosition.width * (pickedImageH/pickedGamePosition.height);
//        var pickedImageLeft = (window.innerWidth/2) - (pickedImageW/2);
        var pickedImageLeft = (document.body.clientWidth/2) - (pickedImageW/2);
        var pickedDeltaX = pickedImageLeft - pickedGamePosition.left;
        var scrollPosition = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        var pickedDeltaY = pickedImageTop - (pickedGamePosition.top + scrollPosition);
        var pickedDeltaScale = pickedImageW / pickedGamePosition.width;
        pickedGame.style.transform = 'translate('+pickedDeltaX+'px, '+pickedDeltaY+'px) scale('+pickedDeltaScale+')';
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    // detect transitionEnd events - should work with all browsers
    function whichTransitionEvent(el){
        var t;
        var transitions = {
          'transition':'transitionend',
          'OTransition':'oTransitionEnd',
          'MozTransition':'transitionend',
          'WebkitTransition':'webkitTransitionEnd'
        }

        for(t in transitions){
            if( el.style[t] !== undefined ){
                return transitions[t];
            }
        }
    }

    createHeadStyleTag();

// GAME VIEW

    // add click event to pick game
    var games = document.getElementsByClassName("js-pick-game");

    var pickGame = function() {
        var pickedGame = this;

        var dataRoomId = pickedGame.parentNode.getAttribute('data-room-id');
        document.querySelector('.data-container').setAttribute('data-room-id', dataRoomId);
        window.roomId = dataRoomId
        var dataRoomName = pickedGame.parentNode.getAttribute('data-room-name');
        document.querySelector('.data-container').setAttribute('data-room-name', dataRoomName);

        pickedGame.parentNode.classList.add('timeView');
        if (window.langs > 1) {
            var languages = document.getElementById('languages');
            languages.classList.add('timeView');
            setTimeout(function() {languages.classList.add('displayNone');}, 500);
        }
        // add timeViewSibling class to not picked games
        var siblings = [];
        var allChildren = pickedGame.parentNode.parentNode.children;
        for (i=0; i < allChildren.length; i++) {
            if (allChildren[i].classList.contains('game-image')) {
                // allChildren[i].querySelector('img').removeEventListener('click', pickGame, false);
                allChildren[i].querySelector('.js-pick-game').removeEventListener('click', pickGame, false);
                if (allChildren[i] != pickedGame.parentNode) allChildren[i].classList.add('timeViewSibling');
            }
        }

        // add translate style to picked image
        positionPickedImage(pickedGame.parentNode);

        var loopCount = pickedGame.parentNode.getAttribute('data-loop');
        // fade in background image
        const bgImageUrl = pickedGame.getAttribute('src');
        const bgImageElement = document.getElementById('bg-image-tag');
        bgImageElement.setAttribute('src', bgImageUrl);
        document.querySelector('#bg-image-container').classList.add('timeView');

        // fade in slots
        document.querySelector('#slots-'+loopCount).classList.add('timeView');

        // remove will change property from elements
        var backgrounds = document.getElementsByClassName('backgrounds');
        for (i = 0; i < backgrounds.length; i++) {
            backgrounds[i].style.willChange = 'unset';
        }
        var gameImages = document.getElementsByClassName('game-image');
        for (i = 0; i < gameImages.length; i++) {
            gameImages[i].style.willChange = 'unset';
        }
        var slots = document.getElementsByClassName('slots');
        for (i = 0; i < slots.length; i++) {
            slots[i].style.willChange = 'unset';
        }

        // add will-change property to slots-text, slot-items and waiver
        var slotsText = document.getElementsByClassName('slots-text');
        for (i = 0; i < slotsText.length; i++) {
            slotsText[i].style.willChange = 'opacity';
        }
        var slotItem = document.getElementsByClassName('slot-item');
        for (i = 0; i < slotItem.length; i++) {
            slotItem[i].style.willChange = 'transform, opacity';
        }
        document.querySelector('.waiver').style.willChange = 'transform';

        // add class op-1 to back button to make button visible
        document.querySelector('.back-button').classList.add('op-1');

    }

    for (var i = 0; i < games.length; i++) {
        games[i].addEventListener('click', pickGame, false);
    }

// TIME VIEW

    var pickTime = function() {
        // select picked time
        var pickedTime = this.parentNode;
        // set slot id in data container
        var dataSlotId = pickedTime.getAttribute('data-slot-id');
        var dataSlotTime = pickedTime.getAttribute('data-slot-time');
        window.slotId = dataSlotId
        window.slotTime = dataSlotTime
        document.querySelector('.data-container').setAttribute('data-slot-id', dataSlotId);
        document.querySelector('.data-container').setAttribute('data-slot-time', dataSlotTime);
        // add waiverView class to picked time and waiver
        pickedTime.classList.add('waiverView');
        document.querySelector('.waiver').classList.add('waiverView');
        // add waiverViewSibling class to not picked times and slot text and remove eventListener
        var allChildren = pickedTime.parentNode.children;
        for (i=0; i < allChildren.length; i++) {
            if (allChildren[i].classList.contains('slots-text')) {
                allChildren[i].classList.add('waiverViewSibling');
            }
            if (allChildren[i].classList.contains('slot-item')) {
                allChildren[i].querySelector('span').removeEventListener('click', pickTime, false);
                if (allChildren[i] != pickedTime) allChildren[i].classList.add('waiverViewSibling');
            }
        }
        // add transform: translate to picked slot item
        var pickedTimeTop = pickedTime.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);
        var translateY = pickedTimeTop - (pickedImageH + pickedImageTop);
        pickedTime.style.transform = 'translateY(-'+translateY+'px)';

        // remove will-change property from slots-text, slot-items and waiver
        var slotsText = document.getElementsByClassName('slots-text');
        for (i = 0; i < slotsText.length; i++) {
            slotsText[i].style.willChange = 'unset';
        }
        var slotItem = document.getElementsByClassName('slot-item');
        for (i = 0; i < slotItem.length; i++) {
            slotItem[i].style.willChange = 'unset';
        }
        document.querySelector('.waiver').style.willChange = 'unset';

        // add room name and slot time to slide down header
        var room_name = document.querySelector('.data-container').getAttribute('data-room-name');
        var slot_time = document.querySelector('.slot-item.waiverView .js-time-click').innerHTML;
        document.querySelector('.slide-down-header').innerHTML = room_name + ' @ ' + slot_time;

        window.scrollTo({ top: 0, behavior: "smooth" })
        document.querySelector('.waiver-text-container').classList.add('safari-scroll-fix')
    }

    var slotItems = document.getElementsByClassName('js-time-click');
    for (var i = 0; i < slotItems.length; i++) {
        slotItems[i].addEventListener('click', pickTime, false);
    }

    const canvas = document.getElementById('signature')
    const signaturePad = new SignaturePad(canvas, { backgroundColor: '#ffffff' })
    const signaturePadClearButton = document.getElementById('clear-signature-pad')
    signaturePadClearButton.addEventListener('click', function() {
        signaturePad.clear()
    })

    export function resizeCanvas() {
        const ratio =  Math.max(window.devicePixelRatio || 1, 1)
        canvas.width = canvas.offsetWidth * ratio
        canvas.height = canvas.offsetHeight * ratio
        canvas.getContext("2d").scale(ratio, ratio)
        signaturePad.clear() // otherwise isEmpty() might return incorrect value
    }

    window.addEventListener("resize", resizeCanvas)
    resizeCanvas()

// SUBMIT WAIVER FORM AFTER SIGNATURE

    const sigSubmit = document.getElementById('sig-submit')
    sigSubmit.addEventListener('click', function() {
        sigSubmit.setAttribute('disabled', 'disabled')
        var inputs = document.querySelectorAll('.waiver-form-field');
        const signature = document.getElementById('signature')
        // var extract_sig = signature.jSignature("getData", "default")
        var extract_sig = signaturePad.toDataURL()
        var input_dict = {};
        for (var i = 0; i < inputs.length; ++i) {
            if (inputs.item(i).name == 'opt_in_checkbox') {
                var check_box_id = '#' + inputs.item(i).id
                input_dict[inputs.item(i).name] = document.querySelector(check_box_id).checked
            } else if (inputs.item(i).name == 'email_waiver_option') {
                var check_box_id = '#' + inputs.item(i).id
                input_dict[inputs.item(i).name] = document.querySelector(check_box_id).checked
            } else if (inputs.item(i).name == 'is_participating') {
                let value = document.querySelector('#' + inputs.item(i).id).value
                const minor_count = document.querySelectorAll('.minor-element').length
                if (minor_count === 0) value = 'true'
                input_dict[inputs.item(i).name] = value
            } else if (inputs.item(i).name == 'first_name' || inputs.item(i).name == 'last_name') {
                input_dict[inputs.item(i).name] = inputs.item(i).value.trim();
            } else if (inputs.item(i).name == 'email') {
                input_dict[inputs.item(i).name] = inputs.item(i).value.trim().toLowerCase();
            } else if (inputs.item(i).name == 'dob' && inputs.item(i).value !== '') {
                var formattedDOB = moment(inputs.item(i).value, "MM/DD/YYYY").format('YYYY-MM-DD');
                input_dict[inputs.item(i).name] = formattedDOB;
            } else if (inputs.item(i).name == 'phone') {
                if (inputs.item(i).value == "") {
                    var formattedPhone = "";
                } else {
                    var formattedPhone = inputs.item(i).value.replace(/[^0-9\+]/g, '');
                }
                input_dict[inputs.item(i).name] = formattedPhone;
            } else if (inputs.item(i).name == 'team_name') {
                input_dict[inputs.item(i).name] = inputs.item(i).value.trim();
            } else if (inputs.item(i).name.substring(0, 6) == 'custom') {
                var customValue = inputs.item(i).name
                var customName = inputs.item(i).getAttribute('data-field-name');
                if (inputs.item(i).name.substring(0, 12) == 'custom_check') {
                    var check_box_id = '#' + inputs.item(i).id
                    input_dict[customValue] = document.querySelector(check_box_id).checked
                } else {
                    input_dict[customValue] = inputs.item(i).value;
                }
                input_dict[customValue + "_name"] = customName;
            } else {
                input_dict[inputs.item(i).name] = inputs.item(i).value;
            }
        }
        input_dict["sig"] = extract_sig;
        input_dict["room_id"] = window.roomId;
        input_dict["slot_id"] = window.slotId
        input_dict["slot_time"] = window.slotTime
        // Variables needed only for SignedWaiver
        input_dict["signed_game"] = document.querySelector('.data-container').getAttribute('data-room-name')
        input_dict["signed_time"] = document.querySelector('.slot-item.waiverView > span').innerText
        input_dict["signed_waiver_content"] = document.querySelector('.waiver-text-container').innerHTML
        input_dict["signed_waiver_header"] = document.querySelector('.waiver-header').innerText
        input_dict["signed_waiver_id"] = document.querySelector('.data-container').getAttribute('data-waiver-id')
        waiver_form_submit(input_dict)
    });

    document.querySelectorAll('input[name="phone"]').forEach(function (phoneInput) {
        function formatPhoneInput(e) {
            const input = e.target
            const inputValue = input.value
            if (e.keyCode !== 8) {
                input.value = phoneFormat(inputValue, window.phoneFormat)
            }
        }
        phoneInput.addEventListener('keyup', formatPhoneInput)
        phoneInput.addEventListener('paste', formatPhoneInput)
    })

    document.querySelectorAll('input[name="dob"]').forEach(function (dobInput) {
        function formatDobInput(e) {
            const input = e.target
            let inputValue = input.value
            if (e.keyCode !== 8) {
                inputValue = inputValue.replace(/[^0-9]/g, '')
                const month = inputValue.substr(0, 2)
                const day = inputValue.substr(2, 2)
                const year = inputValue.substr(4, 4)
                let output = ''
                if (month.length < 2) {
                    output = month
                } else if (month.length == 2 && day.length < 2) {
                    output = month + "/" + day
                } else if (month.length == 2 && day.length == 2) {
                    output = month + "/" + day + "/" + year
                }
                input.value = output
            }
        }
        dobInput.addEventListener('keyup', formatDobInput)
        dobInput.addEventListener('paste', formatDobInput)
      })

    // AJAX for submitting waiver form
    function waiver_form_submit(input_dict) {
        const formData = new FormData()
        for (const key in input_dict) {
            formData.append(key, input_dict[key])
        }
        const url = document.getElementById('sig-submit').getAttribute('data-url')
        const options = {
            headers: {
                'X-CSRFToken': getCookie('csrftoken'),
                'Content-Type': 'multipart/form-data',
            }
        }
        axios.post(url, formData, options)
            .then(function(res) {
                window.location = document.getElementById('sig-submit').getAttribute('data-redirect')
            })
            .catch(function(err) {
                console.error(err)
                const errorDiv = document.getElementById('waiver-submit-errors')
                const div = document.createElement("div")
                const divText = document.createTextNode("Something went wrong. Please try again.")
                div.appendChild(divText)
                div.style.marginBottom = '10px'
                errorDiv.appendChild(div)
                setTimeout(() => {
                    errorDiv.innerHTML = ''
                    sigSubmit.removeAttribute('disabled')
                }, 2000)
            })
    }


// ADD MINOR

    function addMinorInput() {
        const minor_count = document.querySelectorAll('.minor-element').length + 1
        const minorText = {
            "en": "MINOR ",
            "es": "MENOR ",
            "fr": "MINEUR ",
        }

        const newMinor = document.createElement('div')
        newMinor.classList = 'form-group pt-0 minor-element'
        newMinor.innerHTML = `
            <div class="">
                <label class="" for="id_minor_${minor_count}">${minorText[window.language] + minor_count}</label>
                <div class="row">
                    <div class="col-12 minor-row">
                        <input type="minor" name="minor_${minor_count}" class="form-control waiver-form-field" id="id_minor_${minor_count}">
                        <a class="delete-minor" style="cursor:pointer;">
                            <i class="fas fa-times mx-auto"></i>
                        </a>
                    </div>
                </div>
            </div>
        `
        
        const deleteMinorButtons = document.querySelectorAll('.minor-container .delete-minor')
        deleteMinorButtons.forEach(function(btn) {
            btn.classList.add('visibility-hidden')
        })
        const minorContainer = document.querySelector('.minor-container')
        minorContainer.appendChild(newMinor)
        const isParticipatingCheckbox = document.getElementById('is-participating-select')
        if (minor_count === 1) {
            isParticipatingCheckbox.style.display = 'block'
        }
    }


function closeSigModal() {
    const modal = document.getElementById('sigModal')
    modal.classList.remove('show')
    setTimeout(function() {
        const body = document.querySelector('body')
        body.classList.remove('modal-open')
    }, 200)
}

// DOCUMENT CLICK EVENTS
document.addEventListener('click', function(e) {
    const deleteMinorButtons = document.querySelectorAll('.delete-minor')
    const deleteMinorButtonsCount = deleteMinorButtons.length
    const lastDeleteMinorButton = deleteMinorButtonsCount > 0 && deleteMinorButtons[deleteMinorButtonsCount - 1]
    if (lastDeleteMinorButton && lastDeleteMinorButton.contains(e.target)) {
        // remove minor input
        e.target.closest('.minor-element').remove()
        if (deleteMinorButtonsCount > 1) {
            deleteMinorButtons[deleteMinorButtonsCount - 2].classList.remove('visibility-hidden')
        } else {
            const isParticipatingCheckbox = document.getElementById('is-participating-select')
            isParticipatingCheckbox.style.display = 'none'
        }
    } else if (e.target.matches('#add-minor-button')) {
        // add minor input
        addMinorInput()
    } else if (e.target.matches('.js-refresh')) {
        // REFRESH PAGE WITH BACK BUTTON
        location.reload()
    } else if (e.target.matches('#sigModal') || document.querySelector('.sig-close').contains(e.target)) {
        // CLOSE SIG MODAL BY CLICK AWAY OR CLOSE BUTTON
        closeSigModal()
    }
})

// MAKE STICKY HEADER

    window.onscroll = function() {stickyH()};

    function stickyH() {
        const header = document.querySelector('.slide-down-header')
        const slotItemWaiverView = document.querySelector('.slot-item.waiverView')
        if (slotItemWaiverView) {
            const rect = slotItemWaiverView.getBoundingClientRect()
            const trigger = rect.top + window.scrollY
            if (window.pageYOffset > trigger) {
                header.classList.add('sticky')
            } else {
                header.classList.remove('sticky')
            }
        }
    };


// AUTO FILL
const emailInput = document.getElementById('id_email')
if (emailInput) {
    emailInput.addEventListener('change', function() {
        const formData = new FormData()
        const inputs = document.querySelectorAll('#waiver-form .form-group > div > .waiver-form-field')
        inputs.forEach((input) => {
            const inputName = input.getAttribute('name')
            if (inputName !== 'email') {
                const label = input.previousElementSibling.innerText.trim()
                formData.append(inputName, label)
            } else {
                formData.append(inputName, input.value)
            }
        })
        const url = '/waiver/auto-fill/'
        const options = {
            headers: {
                'X-CSRFToken': getCookie('csrftoken'),
                'Content-Type': 'multipart/form-data',
            }
        }
        axios.post(url, formData, options)
            .then(function(res) {
                const data = res.data
                if (data.update === 'yes') {
                    Object.entries(data.fields).forEach((field) => {
                        const key = field[0]
                        const value = field[1]
                        if (key == 'phone' && value != "") {
                            const first = value.slice(0, 3)
                            const second = value.slice(3, 6)
                            const third = value.slice(6, 10)
                            const phone = '('+first+') '+second+'-'+third
                            const input = document.getElementById(`id_${key}`)
                            input.value = phone
                        } else if ((key == 'dob') && (value != null)) {
                            const month = value.slice(5, 7)
                            const day = value.slice(8, 10)
                            const year = value.slice(0, 4)
                            const dob = month+'/'+day+'/'+year
                            const input = document.getElementById(`id_${key}`)
                            input.value = dob
                        } else {
                            const input = document.getElementById(`id_${key}`)
                            input.value = value
                        }
                    })
                }
            })
            .catch(function(err) {
                console.log('auto fill error:', err)
            })
    })
}
