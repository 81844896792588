
// 0 - Universal - 9999999999 or +9999999999
// 1 - North America - (999) 999-9999

export function phoneFormat(input, formatType) {
    let output = ""
    switch (formatType) {
        case "0":
            output = universalPhoneFormat(input)
            break
        case "1":
            output = northAmericaPhoneFormat(input)
            break
    }
    return output
}

function universalPhoneFormat(input) {
    let firstChar = input.substring(0, 1).replace(/[^0-9\+]/g, '')
    let restOfString = input.substring(1, 17).replace(/[^0-9]/g, '')
    let x = firstChar + restOfString
    return x.match(/^\+?1?/) + x.replace(/^\+?1?/, '').substring(0, 15)
}

function northAmericaPhoneFormat(input) {
    input = input.replace(/[^0-9]/g, '')
    let area = input.substring(0, 3)
    let pre = input.substring(3, 6)
    let tel = input.substring(6, 10)
    let output = ""
    if (area.length > 0 && area.length < 3) {
      output = "(" + area
    } else if (area.length == 3 && pre.length < 3) {
      output = "(" + area + ")" + " " + pre
    } else if (area.length == 3 && pre.length == 3) {
      output = "(" + area + ")" + " " + pre + "-" + tel
    }
    return output
}

export function getPhoneFormatConstraints(constraints={}) {
    switch (window.phoneFormat) {
        case "0":
            constraints.format = {
              pattern: /\+?1?\d{9,15}$/,
              message: "^Phone number must contain between 9 and 15 digits"
            }
            break
        case "1":
          constraints.format = {
            pattern: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
            message: "^Phone number must contain exactly 10 numbers"
          }
          break
    }
    return constraints;
}